import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  Upload,
  Image,
  Card,
} from "antd";
import { CloseCircleFilled, InboxOutlined } from "@ant-design/icons";
import type { UploadFile, RcFile } from "antd/es/upload/interface";
import { getData, token } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { TGender } from "../../../../custom-hooks/useAllGender";
import useWorldCountries from "../../../../custom-hooks/useWorldCountries";
import useCountryStates from "../../../../custom-hooks/useCountryStates";
import useMaritalStatuses from "../../../../custom-hooks/useMaritalStatuses";
import useNextOfKinRelationships from "../../../../custom-hooks/useNextOfKinRelationships";
import {
  handleSwalErrorAlert,
  handleSwalSuccessAlert,
} from "../../../../helpers";
import { errorHandler } from "../../../../helpers/errorHandler";
import { appInsights } from "../../../../config/appInsights";
import axios from "axios";
import { BASE_URL2 } from "../../../../appConstants";

interface ISignUpIndiv {
  genderData: TGender[];
  parentFormData: ParentFormData;
}

interface ParentFormData {
  funder_type_id: string | number;
  country_code: string;
  effective_date: string;
}

interface ChildFormData {
  // Personal Information
  identification_number: string;
  nin: string;
  first_name: string;
  last_name: string;
  MiddleName: string;
  gender: string;
  dateOfBirth: string;
  phone_number: string;
  email: string;
  marital_status: string;

  // Residential Information
  state: string;
  city: string;
  street_address: string;
  proof_of_address?: File;

  // Next of Kin Information
  // Updated Next of Kin Information
  next_of_kin_first_name: string;
  next_of_kin_last_name: string;
  next_of_kin_phone: string;
  next_of_kin_email: string;
  next_of_kin_relationship: string;
  next_of_kin_street_address: string;
  next_of_kin_city: string;
  next_of_kin_state: string;
  next_of_kin_country: string;
}

export default function SignUpDigitalBankIndividualInvestor({
  genderData,
  parentFormData,
}: ISignUpIndiv) {
  const [form] = Form.useForm<ChildFormData>();
  const [isFetchingBvnDetails, setIsFetchingBvnDetails] = useState(false);
  const [proofOfAddress, setProofOfAddress] = useState<UploadFile[]>([]);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [transaction_id, setTransaction_id] = useState("");

  useEffect(() => {
    appInsights.trackPageView({
      name: "SignUpDigitalBankIndividualInvestor.tsx",
    });
  }, []);

  const { data: countries } = useWorldCountries(3);
  const { data: maritalStatuses, isLoading: isLoadingMaritalStatuses } =
    useMaritalStatuses();
  const {
    data: nextOfKinRelationship,
    isLoading: isLoadingNextOfKinRelationship,
  } = useNextOfKinRelationships();

  const resolveCountryInfo = countries?.data?.find(
    ({ iso2 }: { iso2: string }) => iso2 === "NG",
  );

  const { data: states, isLoading: isStatesLoading } = useCountryStates(
    resolveCountryInfo?.id,
  );

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleBvnValidation = async (value: string) => {
    if (value.length === 11 && /^\d+$/.test(value)) {
      setIsFetchingBvnDetails(true);

      try {
        const response = await getData(
          `${ajaxEndpoints.VERIFY_KYC}?IdentificationNumber=${value}&CountryCode=NG`,
        );
        const { first_name, last_name, formatted_dob, gender, transaction_id } =
          response.data?.data;

        form.setFieldsValue({
          first_name,
          last_name,
          gender,
          dateOfBirth: formatted_dob,
        });
        setTransaction_id(transaction_id);
      } catch (error: any) {
        message.error(
          error?.response?.data?.message || "BVN verification failed",
        );
        form.resetFields(["first_name", "last_name", "gender", "dateOfBirth"]);
      } finally {
        setIsFetchingBvnDetails(false);
      }
    }
  };

  // Create FormData object for submission
  const createFormData = async (values: ChildFormData) => {
    const formData = new FormData();

    // Add parent form data
    formData.append("CountryCode", "NG");

    // Map form values to matching Postman keys
    const keyMapping: Record<
      keyof Omit<ChildFormData, "proof_of_address">,
      string
    > = {
      first_name: "FirstName",
      last_name: "LastName",
      MiddleName: "MiddleName",
      identification_number: "Bvn",
      nin: "Nin",
      gender: "Gender",
      dateOfBirth: "DateOfBirth",
      email: "Email",
      phone_number: "PhoneNumber",
      marital_status: "MaritalStatus",
      state: "State",
      city: "City",
      street_address: "Address",
      next_of_kin_first_name: "NextOfKinFirstName",
      next_of_kin_last_name: "NextOfKinLastName",
      next_of_kin_email: "NextOfKinEmail",
      next_of_kin_phone: "NextOfKinPhone",
      next_of_kin_relationship: "NextOfKinRelationshipType",
      next_of_kin_street_address: "NextOfKinStreet",
      next_of_kin_city: "NextOfKinCity",
      next_of_kin_state: "NextOfKinState",
      next_of_kin_country: "NextOfKinCountry",
    };

    // Add mapped form values
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && value !== null && key !== "proof_of_address") {
        const mappedKey =
          keyMapping[key as keyof Omit<ChildFormData, "proof_of_address">];

        if (mappedKey) {
          formData.append(mappedKey, String(value));
        }
      }
    });

    // Handle proof of address document
    if (proofOfAddress[0]?.originFileObj) {
      const file = proofOfAddress[0].originFileObj;

      console.log({ file });
      formData.append("CustomerDocuments[0].Document", file);
      formData.append("CustomerDocuments[0].Type", "Utility Bill");
      formData.append("CustomerDocuments[0].DocumentTypeCode", "PAI");

      // Debug log
      // @ts-ignore
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
    }

    // Log FormData to verify final structure
    // @ts-ignore
    for (const pair of formData.entries()) {
      console.log(
        `${pair[0]}:`,
        pair[1] instanceof File ? `File: ${(pair[1] as File).name}` : pair[1],
      );
    }

    // Log the entire FormData to verify content
    console.log("FormData entries:");
    // @ts-ignore
    for (const pair of formData.entries()) {
      console.log(pair[0], ":", pair[1]);
    }

    // Add transaction ID
    formData.append("TransactionId", transaction_id);

    // Add NextOfKinDialCode separately
    formData.append("NextOfKinDialCode", "+234");
    try {
      setIsLoading(true);
      const response = await axios({
        method: "POST",
        url: `${BASE_URL2}${ajaxEndpoints.ONBOARD_DIGITAL_BANK_CUSTOMER}`,
        data: formData,
        headers: {
          Authorization: `Token ${token()}`,
          // Let axios set the content-type header automatically for FormData
        },
      });
      handleSwalSuccessAlert(response.data?.message, () => {
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
      handleSwalErrorAlert(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "SignUpDigitalBankIndividualInvestor.tsx" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: ChildFormData) => {
    if (proofOfAddress.length === 0) {
      message.error("Please upload proof of address");
      return;
    }

    // Check if phone number starts with zero, if not, prepend zero
    if (!values.phone_number.startsWith("0")) {
      values.phone_number = "0" + values.phone_number;
    }

    createFormData(values);
  };

  // Custom styles for sections
  const sectionStyle = {
    marginBottom: "2rem",
    boxShadow: "0 2px 8px rgba(113,63,255,0.1)",
    borderRadius: "8px",
    border: "1px solid #f0f0f0",
    transition: "all 0.3s ease",
  };

  const sectionTitleStyle = {
    fontSize: "18px",
    color: "#713FFF",
    borderBottom: "1px solid #f0f0f0",
    padding: "16px 24px",
    margin: "-1px -1px 0",
    borderRadius: "8px 8px 0 0",
    backgroundColor: "#FAFAFA",
  };

  const contentStyle = {
    padding: "24px",
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      size="large"
      className="px-3 py-4"
    >
      {/* Section 1: Identity Information */}
      <Card style={sectionStyle} bordered={false}>
        <div style={sectionTitleStyle}>Identity Information</div>
        <div style={contentStyle} className="row">
          <div className="col-md-6">
            <Form.Item
              label="BVN"
              name="identification_number"
              rules={[
                { required: true, message: "BVN is required" },
                {
                  pattern: /^\d{11}$/,
                  message: "BVN must be exactly 11 digits",
                },
              ]}
            >
              <Input
                maxLength={11}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  form.setFieldValue("identification_number", value);
                  if (value.length === 11) {
                    handleBvnValidation(value);
                  }
                }}
                disabled={isFetchingBvnDetails}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="NIN"
              name="nin"
              rules={[
                { required: true, message: "NIN is required" },
                {
                  pattern: /^\d{11}$/,
                  message: "NIN must be exactly 11 digits",
                },
              ]}
            >
              <Input
                maxLength={11}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  form.setFieldValue("nin", value);
                }}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: "First name is required" }]}
            >
              <Input disabled />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: "Last name is required" }]}
            >
              <Input disabled />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Middle Name" name="MiddleName">
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Gender is required" }]}
            >
              <Select disabled>
                {genderData.map((gender, index) => (
                  <Select.Option key={index} value={gender.name}>
                    {gender.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Date of Birth"
              name="dateOfBirth"
              rules={[{ required: true, message: "Date of birth is required" }]}
            >
              <Input disabled />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Marital Status"
              name="marital_status"
              rules={[
                { required: true, message: "Marital status is required" },
              ]}
            >
              <Select>
                {maritalStatuses?.data?.map(
                  ({ name, id }: { name: string; id: number }) => (
                    <Select.Option key={id} value={name}>
                      {name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Phone Number (81*********)"
              name="phone_number"
              rules={[
                { required: true, message: "Phone number is required" },
                {
                  pattern: /^\d{10}$/,
                  message: "Phone number must be 10 digits",
                },
              ]}
            >
              <Input
                addonBefore="+234"
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  form.setFieldValue("phone_number", value);
                }}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Email is required" },
                { type: "email", message: "Invalid email format" },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
      </Card>

      {/* Section 2: Residential Information */}
      <Card style={sectionStyle} bordered={false}>
        <div style={sectionTitleStyle}>Residential Information</div>
        <div style={contentStyle} className="row">
          <div className="col-md-6">
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: "State is required" }]}
            >
              <Select>
                {states?.data
                  ?.sort((a: { name: string }, b: { name: any }) =>
                    a.name.localeCompare(b.name),
                  )
                  .map(({ name, id }: { name: string; id: number }) => {
                    return (
                      <Select.Option key={id} value={name}>
                        {name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "City is required" }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="Street Address"
              name="street_address"
              rules={[
                { required: true, message: "Street address is required" },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="Proof of Address"
              name="proof_of_address"
              rules={[
                { required: true, message: "Proof of address is required" },
              ]}
            >
              <div className="space-y-4">
                <Upload.Dragger
                  accept="image/png,image/jpeg,image/jpg"
                  maxCount={1}
                  fileList={proofOfAddress}
                  onChange={async ({ fileList }) => {
                    const newFileList = fileList
                      .map((file) => {
                        if (file.type && !file.type.startsWith("image/")) {
                          message.error(`${file.name} is not an image file`);
                          return null;
                        }
                        return file;
                      })
                      .filter(Boolean) as UploadFile[];

                    setProofOfAddress(newFileList);

                    if (newFileList[0]?.originFileObj) {
                      const url = await getBase64(
                        newFileList[0].originFileObj as RcFile,
                      );
                      setImageUrl(url);
                    } else {
                      setImageUrl("");
                    }
                  }}
                  beforeUpload={(file) => {
                    const isImage = file.type.startsWith("image/");
                    if (!isImage) {
                      message.error("You can only upload image files!");
                      return Upload.LIST_IGNORE;
                    }

                    const isLt5M = file.size / 1024 / 1024 < 5;
                    if (!isLt5M) {
                      message.error("Image must be smaller than 5MB!");
                      return Upload.LIST_IGNORE;
                    }
                    return false;
                  }}
                  style={{ padding: "20px" }}
                >
                  <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined
                        style={{ fontSize: "24px", color: "#713FFF" }}
                      />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag image to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for PNG, JPG, or JPEG. File size limit: 5MB
                    </p>
                  </div>
                </Upload.Dragger>

                {imageUrl && (
                  <div className="mt-4">
                    <p className="mb-2 fw-medium">Preview:</p>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Image
                        src={imageUrl}
                        alt="Proof of Address"
                        style={{ maxHeight: "200px" }}
                        className="d-block"
                        preview={false}
                      />
                      <Button
                        type="text"
                        className="ant-btn-dangerous"
                        icon={<CloseCircleFilled />}
                        onClick={() => {
                          setImageUrl("");
                          setProofOfAddress([]);
                          form.setFieldValue("proof_of_address", undefined);
                        }}
                        style={{
                          position: "absolute",
                          top: 4,
                          right: 4,
                          backgroundColor: "white",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          borderRadius: "50%",
                          padding: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Form.Item>
          </div>
        </div>
      </Card>

      {/* Section 3: Next of Kin */}
      <Card style={sectionStyle} bordered={false}>
        <div style={sectionTitleStyle}>Next of Kin</div>
        <div style={contentStyle} className="row">
          <div className="col-md-6">
            <Form.Item
              label="First Name"
              name="next_of_kin_first_name"
              rules={[
                {
                  required: true,
                  message: "Next of kin first name is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Last Name"
              name="next_of_kin_last_name"
              rules={[
                {
                  required: true,
                  message: "Next of kin last name is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Phone Number"
              name="next_of_kin_phone"
              rules={[
                { required: true, message: "Phone number is required" },
                {
                  pattern: /^\d{11}$/,
                  message: "Phone number must be 11 digits",
                },
              ]}
            >
              <Input
                maxLength={11}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  form.setFieldValue("next_of_kin_phone", value);
                }}
                placeholder="Enter phone number"
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Email Address"
              name="next_of_kin_email"
              rules={[
                { required: true, message: "Next of kin email is required" },
                { type: "email", message: "Invalid email format" },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="Relationship"
              name="next_of_kin_relationship"
              rules={[{ required: true, message: "Relationship is required" }]}
            >
              <Select>
                {nextOfKinRelationship?.data?.map(
                  ({ name, id }: { name: string; id: number }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Country"
              name="next_of_kin_country"
              initialValue="NG"
              rules={[{ required: true, message: "Country is required" }]}
            >
              <Select>
                {countries?.data?.map(
                  ({ name, iso2 }: { name: string; iso2: string }) => (
                    <Select.Option key={iso2} value={iso2}>
                      {name}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-12">
            <Form.Item
              label="Street Address"
              name="next_of_kin_street_address"
              rules={[
                { required: true, message: "Street address is required" },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="City"
              name="next_of_kin_city"
              rules={[{ required: true, message: "City is required" }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              label="State"
              name="next_of_kin_state"
              rules={[{ required: true, message: "State is required" }]}
            >
              <Select>
                {states?.data
                  ?.sort((a: { name: string }, b: { name: any }) =>
                    a.name.localeCompare(b.name),
                  )
                  .map(({ name, id }: { name: string; id: number }) => (
                    <Select.Option key={id} value={name}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Card>

      {/* Submit Button with enhanced styling */}
      <div className="text-center mt-5">
        <Button
          type="primary"
          htmlType="submit"
          loading={isFetchingBvnDetails || isLoading}
          size="large"
          style={{
            minWidth: "200px",
            height: "48px",
            fontSize: "16px",
            borderRadius: "24px",
            background: "linear-gradient(to right, #713fff, #8b6fff)",
            border: "none",
            boxShadow: "0 2px 8px rgba(113,63,255,0.35)",
          }}
        >
          {isFetchingBvnDetails || isLoading
            ? "Please wait..."
            : "Create Account"}
        </Button>
      </div>
    </Form>
  );
}
