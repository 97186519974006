import { ChangeEvent, FormEvent, Key, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import moment from "moment";
import useAllBanksByCountryCode from "../../../../custom-hooks/useAllBanksByCountryCode";
import useCountryCodes from "../../../../custom-hooks/useCountryCodes";
import useAllGender from "../../../../custom-hooks/useAllGender";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import SignUpCorporate from "./SignUpCorporate";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import CustomInputField from "../../../../NewComponents/CustomHTMLElements/CustomInputField";
import { investorType } from "../../../../components/Investor/util";
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { hideModal } from "../../../../redux/actions/modal";
import { appInsights } from "../../../../config/appInsights";
import { errorHandler } from "../../../../helpers/errorHandler";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";
import SignUpDigitalBankIndividualInvestor from "./SignUpDigitalBankIndividualInvestor";
import SignUpIndiv from "./SignUpIndiv";

export interface ISubmitState {
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  gender: string;
  dateOfBirth: string;
  biz_email: string;
  biz_name: string;
  rc_number: string;
  phone_number: string;
  bank_id: string;
  bank_account_number: string;
  identification_number: string;
  nin: string;
  funder_type_id: string | number;
  effective_date: string;
}

const defaultSubmitState: ISubmitState = {
  first_name: "",
  last_name: "",
  email: "",
  country_code: "",
  gender: "",
  biz_email: "",
  biz_name: "",
  rc_number: "",
  phone_number: "",
  bank_id: "",
  bank_account_number: "",
  identification_number: "",
  nin: "",
  funder_type_id: 1,
  effective_date: "",
  dateOfBirth: "",
};

const SignUpInvestor = () => {
  const [signUpError, setSignUpError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitState, setSubmitState] =
    useState<ISubmitState>(defaultSubmitState);

  const dispatch = useDispatch();

  useEffect(() => {
    MixPanel.track(`Page View: SignUpInvestor.tsx`, {
      page: "SignUpInvestor.tsx",
    });
  }, []);

  const handleSubmitChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setSubmitState({
      ...submitState,
      [name]: value,
    });
  };

  const handleFunderChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSubmitState({
      ...submitState,
      first_name: "",
      last_name: "",
      email: "",
      country_code: "",
      gender: "",
      biz_email: "",
      biz_name: "",
      rc_number: "",
      phone_number: "",
      bank_id: "",
      bank_account_number: "",
      identification_number: "",
      [name]: value,
    });
  };
  const { data: countryCodes } = useCountryCodes();
  const { data: genderData } = useAllGender(submitState.country_code);
  const { data: allBanks } = useAllBanksByCountryCode(submitState.country_code);

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    const {
      first_name,
      last_name,
      email,
      country_code,
      gender,
      biz_email,
      biz_name,
      rc_number,
      phone_number,
      bank_id,
      bank_account_number,
      identification_number: identification_number,
      funder_type_id,
      effective_date,
    } = submitState;

    setSignUpError("");
    setLoading(true);

    let reqBody = {};
    if (
      funder_type_id === investorType?.INDIVIDUAL ||
      funder_type_id === investorType?.INDIVIDUAL.toString()
    ) {
      reqBody = {
        first_name,
        phone_number,
        email,
        last_name,
        gender,
        funder_type_id: Number(funder_type_id),
        country_code,
        bank_id: country_code === "NG" ? undefined : bank_id,
        bank_account_number:
          country_code === "NG" ? undefined : bank_account_number,
        identification_number,
        effective_date,
      };
    } else {
      reqBody = {
        first_name,
        phone_number,
        email,
        biz_email,
        biz_name,
        rc_number,
        last_name,
        gender,
        funder_type_id: Number(funder_type_id),
        country_code,
        bank_id: Number(bank_id),
        bank_account_number,
        effective_date,
      };
    }

    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.ADMIN_SIGNUP_FUNDER,
        reqBody,
      );

      setSuccessMessage(response.message);
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
      setTimeout(() => {
        dispatch(hideModal());
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Investor.jsx",
        },
      });
      setSignUpError(errorHandler(error));
      setTimeout(() => {
        dispatch(hideModal());
      }, 5000);

      setTimeout(() => {
        setSignUpError("");
      }, 5000);
    }
    setLoading(false);
  };
  const funder_type_id = submitState.funder_type_id;
  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      {signUpError && <Alert message={signUpError} />}
      {successMessage && <Alert message={successMessage} type="success" />}
      <div className="Modal__body">
        <h3 className="Modal__title p-b-2">Create Investor</h3>
        <hr />

        <div className="row">
          <div className="col-6 mb-1">
            <CustomSelectDropdown
              name="funder_type_id"
              label="Investor Type"
              value={String(funder_type_id)}
              onChange={handleFunderChange}
              className="round user__form form-control"
            >
              <option value={investorType?.INDIVIDUAL}>Individual</option>
              <option value={investorType?.CORPORATE}>Corporate</option>
            </CustomSelectDropdown>
          </div>
          <div className="col-6 mb-1">
            <CustomInputField
              type="date"
              name="effective_date"
              label="Creation Date"
              max={moment(new Date()).format("YYYY-MM-DD")}
              defaultValue={submitState.effective_date}
              onChange={handleSubmitChange}
            ></CustomInputField>
          </div>

          {(funder_type_id === investorType?.INDIVIDUAL ||
            funder_type_id === investorType?.INDIVIDUAL.toString()) && (
            <div className="col-12 mb-3">
              <CustomSelectDropdown
                name="country_code"
                label="Investor's Country"
                className="round user__form form-control"
                onChange={handleSubmitChange}
              >
                <option value="">Choose Country</option>
                {countryCodes?.data &&
                  countryCodes.data.map(
                    (
                      country: { country_code: any; name: any },
                      index: Key | null | undefined,
                    ) => {
                      const { country_code, name } = country;
                      return (
                        <option value={country_code} key={index}>
                          {name}
                        </option>
                      );
                    },
                  )}
              </CustomSelectDropdown>
            </div>
          )}
        </div>

        {/* Form section  */}
        {submitState?.country_code !== "NG" && (
          <form className="mt-5" onSubmit={submit}>
            <div className="row">
              {(funder_type_id === investorType?.CORPORATE ||
                funder_type_id === investorType?.CORPORATE.toString()) && (
                <SignUpCorporate
                  countryCodes={!countryCodes ? [] : countryCodes?.data}
                  allBanks={!allBanks ? [] : allBanks?.data}
                  handleSubmitChange={handleSubmitChange}
                  submitState={submitState}
                  genderData={!genderData ? [] : genderData?.data}
                />
              )}
              {submitState?.country_code === "KE" &&
                (funder_type_id === investorType?.INDIVIDUAL ||
                  funder_type_id === investorType?.INDIVIDUAL.toString()) && (
                  <SignUpIndiv
                    countryCodes={!countryCodes ? [] : countryCodes?.data}
                    allBanks={!allBanks ? [] : allBanks?.data}
                    handleSubmitChange={handleSubmitChange}
                    submitState={submitState}
                    genderData={!genderData ? [] : genderData?.data}
                  />
                )}
            </div>
            <div className="my-3 text-right">
              <button
                type="submit"
                value="Submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
                disabled={loading}
              >
                Create Account
                {loading && <ButtonLoader />}
              </button>
            </div>
          </form>
        )}

        {submitState?.country_code === "NG" &&
          (funder_type_id === investorType?.INDIVIDUAL ||
            funder_type_id === investorType?.INDIVIDUAL.toString()) && (
            <SignUpDigitalBankIndividualInvestor
              genderData={!genderData ? [] : genderData?.data}
              parentFormData={{
                country_code: submitState.country_code,
                funder_type_id: submitState.funder_type_id,
                effective_date: submitState.effective_date,
              }}
            />
          )}
      </div>
    </div>
  );
};
export default SignUpInvestor;
